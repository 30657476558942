import {
  Checkbox,
  Icons,
  LinkButton,
  Typography,
  dayjs,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  CommercialConditionsContainer,
  CommercialConditionsSummary,
  ContractItem,
  TextContainer,
} from './commercial-conditions-step.style';
import { InfoBlock } from '../InfoBlock';
import { useEffect, useState } from 'react';
import { ContractModal } from '@/components/Modals/ContractModal';
import { DividedContent } from '../DividedContent';
import { useTranslation } from 'react-i18next';
import { Module } from '../../contract.page';
import { LoadingState } from '@/components';
import { ContractSelector } from '../ContractSelector';
import { useGetContractsSummary } from '../../hooks/use-get-contracts-summary';

interface CommercialConditionsStepProps {
  contractChecks: { [key: string]: boolean };
  onContractCheck: (contractId: string, checked: boolean) => void;
  subscription: {
    id: string;
    contractIds: string[];
    plans: { name: string; id: string; moduleIds: string }[];
    modules: Module[];
  };
}

export const CommercialConditionsStep = ({
  contractChecks,
  onContractCheck,
  subscription,
}: CommercialConditionsStepProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'contract.commercialConditionsStep',
  });
  const {
    getContractsSummary,
    data: contractsSummary,
    isLoading,
  } = useGetContractsSummary();

  const [selectedContractIndex, setSelectedContractIndex] = useState(0);

  const handleSelectContract = (index: number) => {
    setSelectedContractIndex(index);
  };

  const { contractIds = [], modules = [] } = subscription;
  const [modalContractId, setModalContractId] = useState('');

  useEffect(() => {
    const loadContractSummary = async () => {
      await getContractsSummary({ contractIds });
    };

    if (contractIds.length) loadContractSummary();
  }, [contractIds]);

  const closeModal = () => setModalContractId('');

  const handleOpenModal = (contractId: string) => {
    setModalContractId(contractId);
  };

  const getContractModules = () => {
    const contractPlanIds = contractsSummary
      ? contractsSummary[selectedContractIndex]?.planIds
      : [];
    const contractModules =
      subscription?.plans
        ?.filter((plan) => contractPlanIds.includes(plan.id))
        ?.flatMap((plan) => plan.moduleIds) || [];
    return modules.filter((module) => contractModules.includes(module.id));
  };

  if (!contractsSummary || isLoading) {
    return <LoadingState />;
  }

  const selectecContract = contractsSummary[selectedContractIndex];
  const contractEndDate = dayjs(selectecContract?.expirationAt).format(
    'DD/MM/YYYY',
  );

  const contractModules = getContractModules();

  return (
    <>
      <DividedContent
        header="Condições comerciais"
        description="Leia e aceite as condições comerciais do Contrato de Prestação de
            Serviços da Flash."
      >
        {contractsSummary.length > 1 && (
          <ContractSelector
            onSelectContract={handleSelectContract}
            contracts={contractsSummary.map((contract, index) => ({
              id: contract.contractId,
              title: contract.name,
              isSelected: index === selectedContractIndex,
            }))}
          />
        )}
        <CommercialConditionsSummary>
          <TextContainer>
            <Typography variant="headline8" color="neutral.20">
              {t('title')}
            </Typography>
            <Typography variant="body3" color="neutral.50">
              {t('conditionsEndDateText', {
                endDate: contractEndDate,
                interpolation: { escapeValue: false },
              })}
            </Typography>
            <LinkButton
              variant="neutral"
              onClick={() => setModalContractId(selectecContract.contractId)}
              style={{ alignSelf: 'flex-start' }}
            >
              <Icons
                name="IconNotes"
                fill="none"
                color="var(--color-neutral-50)"
                size={16}
              />{' '}
              {t('viewContractText')}
            </LinkButton>
          </TextContainer>
          <CommercialConditionsContainer>
            <InfoBlock
              icon="IconBox"
              title="Produtos do pacote de contratação padrão"
              description="Você poderá personalizar seus produtos posteriormente"
              items={contractModules}
            />
            <InfoBlock
              icon="IconReceipt2"
              title="Tarifas dos produtos"
              items={
                selectecContract?.commercialConditions.map((item) => ({
                  name: item.name,
                  description: item.value,
                })) ?? []
              }
            />
          </CommercialConditionsContainer>
          <div>
            {contractsSummary.map((contractSummary) => (
              <>
                <ContractItem>
                  <Checkbox
                    checked={contractChecks[contractSummary.contractId]}
                    onChange={(e) =>
                      onContractCheck(
                        contractSummary.contractId,
                        e.target.checked,
                      )
                    }
                  />
                  <Typography variant="body3" color="neutral.50">
                    {t('readContractText')}{' '}
                    <Typography
                      tag="a"
                      variant="body3"
                      color="secondary.50"
                      onClick={() =>
                        handleOpenModal(contractSummary.contractId)
                      }
                      style={{ cursor: 'pointer', textDecoration: 'underline' }}
                    >
                      {contractSummary.name}
                    </Typography>
                  </Typography>
                </ContractItem>
                <ContractModal
                  open={modalContractId === contractSummary.contractId}
                  contractId={contractSummary.contractId}
                  onClose={closeModal}
                />
              </>
            ))}
          </div>
        </CommercialConditionsSummary>
      </DividedContent>
    </>
  );
};
