import styled from 'styled-components';

export const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: ${({ theme }) => theme.spacings.l};
`;

export const ProductGroupTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin: ${({ theme }) => `${theme.spacings.l} 0 ${theme.spacings.m} 0`};
  gap: ${({ theme }) => theme.spacings.xs5};
`;
