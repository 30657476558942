import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { BannersSectionContainer, ContentContainer } from './layout-1.styles';
import { PrimaryBanner } from '../PrimaryBanner';
import { BannerProps } from '../banners-section.dto';
import { useTranslation } from 'react-i18next';

export interface BannersLayout1Props {
  banners: [BannerProps];
}

export const BannersLayout1 = (props: BannersLayout1Props) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.bannersSection',
  });
  return (
    <BannersSectionContainer>
      <ContentContainer>
        <Typography
          color="secondary.50"
          variant="headline9"
          style={{ marginBottom: '12px' }}
        >
          {t('title')}
        </Typography>
        <PrimaryBanner
          contentStyle={{ width: '50%' }}
          imageStyle={{ height: '90%', bottom: 0 }}
          backgroundColor="var(--color-secondary-90)"
          dataOnboarding="app_store_first_banner"
          {...props.banners[0]}
          buttonText="Quero conhecer!"
        />
      </ContentContainer>
    </BannersSectionContainer>
  );
};
