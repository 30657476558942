import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useUpdateFlagsContext } from '@flash-tecnologia/feature-flags';
import { OverviewPage, ComparePage } from '@/pages/Acquisition';
import { ContractPage } from '@/pages/Acquisition/Contract';
import { useRegisterRoutes } from '@flash-tecnologia/hros-web-utility';
import { AppStorePage } from '@/pages/Acquisition/AppStore';
import { useEffect } from 'react';
import { useSession } from '@/shared/hooks';

const AppRouter = () => {
  const { userId, companyId, economicGroupId } = useSession();
  const updateContext = useUpdateFlagsContext();
  useRegisterRoutes([
    {
      hideHeader: true,
      path: '/acquisition/contract',
    },
  ]);

  useEffect(() => {
    updateContext(userId, {
      economicGroupId,
      companyId,
      employeeId: userId,
    });
  }, [userId, companyId]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="acquisition">
          <Route path="overview" element={<OverviewPage />} />
          <Route path="app-store" element={<AppStorePage />} />
          <Route path="compare" element={<ComparePage />} />
          <Route path="contract" element={<ContractPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
