export interface Offer {
  marketPageId: string;
  imageUrl: string;
  title: string;
  description: string;
  topics: Array<{
    icon: string;
    text: string;
  }>;
  ctaText: string;
}

export const expenseSaasBasic: Offer = {
  marketPageId: 'sales_flash_expense_basic',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/expense/recommendations/gestao-despesas.png',
  title: 'Gestão de Despesas',
  description:
    'Para empresas que desejam ter mais controle sobre os gastos com suas despesas corporativas.',
  topics: [
    { icon: 'IconCreditCard', text: 'Cartão Corporativo Inteligente' },
    { icon: 'IconClockHour5', text: 'Gestão de Despesas' },
  ],
  ctaText: 'Saiba Mais',
};
export const expenseSaasBasicV202501: Offer = {
  marketPageId: 'sales_flash_expense_basic_v202501',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/expense/recommendations/gestao-despesas.png',
  title: 'Gestão de Despesas',
  description:
    'Para empresas que desejam ter mais controle sobre os gastos com suas despesas corporativas.',
  topics: [
    { icon: 'IconCreditCard', text: 'Cartão Corporativo Inteligente' },
    { icon: 'IconClockHour5', text: 'Gestão de Despesas' },
  ],
  ctaText: 'Saiba Mais',
};

export const benefitsBasic: Offer = {
  marketPageId: 'sales_flash_benefits_basic',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/benefits/recommendations/gestao-beneficios.png',
  title: 'Flash Benefícios',
  description:
    'Um só cartão com multibenefícios: vale-alimentação e refeição, saúde, auxílio mobilidade, educação, auxílio home office, cultura, vale-transporte, premiação e mais.',
  // TODO: update topics
  topics: [
    { icon: 'IconCreditCard', text: 'Cartão multibenefícios' },
    { icon: 'IconBuildingStore', text: 'Parcerias de descontos' },
  ],
  ctaText: 'Saiba Mais',
};

export const peopleRhStrategic: Offer = {
  marketPageId: 'sales_flash_rh_strategic',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/people/recommendations/gestao-estrategica-rh.png',
  title: 'Gestão Estratégica de RH',
  description:
    'Potencialize a gestão de talentos da sua empresa realizando de forma estratégica treinamentos, avaliações de desempenho, feedbacks, pesquisas e muito mais.',
  topics: [
    { icon: 'IconSchool', text: 'Criação de treinamentos próprios' },
    { icon: 'IconRocket', text: 'Avaliação, feedback e PDI' },
    { icon: 'IconSpeakerphone', text: 'Comunicados e pesquisas' },
  ],
  ctaText: 'Saiba Mais',
};

export const peopleRhOps: Offer = {
  marketPageId: 'sales_flash_rh_ops',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/people/recommendations/gestao-operacional-rh.png',
  title: 'Gestão Operacional de RH',
  description:
    'Impulsione sua gestão de pessoas com uma solução integrada que centraliza toda a jornada do colaborador, desde a admissão e controle de ponto.',
  topics: [
    { icon: 'IconCreditCard', text: 'Benefícios' },
    { icon: 'IconUserPlus', text: 'Admissão e desligamento digital' },
    { icon: 'IconClockHour5', text: 'Controle de ponto e férias' },
  ],
  ctaText: 'Saiba Mais',
};

export const peopleRhFull: Offer = {
  marketPageId: 'sales_flash_rh_full',
  imageUrl:
    'https://images.flashapp.com.br/flash-os/acquisition/people/recommendations/gestao-completa-rh.png',
  title: 'Gestão Completa de RH',
  description:
    'Liberte seu RH e DP do trabalho operacional com uma plataforma completa que reduz as ferramentas contratadas em até 85%.',
  topics: [
    { icon: 'IconUserPlus', text: 'Admissão e desligamento digital' },
    { icon: 'IconClockHour5', text: 'Controle de ponto e férias' },
    { icon: 'IconRocket', text: 'Treinamentos, performance e engajamento' },
  ],
  ctaText: 'Saiba Mais',
};

export const OfferRecommendationMap = {
  [expenseSaasBasic.marketPageId]: expenseSaasBasic,
  [expenseSaasBasicV202501.marketPageId]: expenseSaasBasicV202501,
  [benefitsBasic.marketPageId]: benefitsBasic,
  [peopleRhStrategic.marketPageId]: peopleRhStrategic,
  [peopleRhOps.marketPageId]: peopleRhOps,
  [peopleRhFull.marketPageId]: peopleRhFull,
};
