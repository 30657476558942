import { useGetAllowedOffersByActiveSubscription } from './use-get-allowed-offers-by-active-subscription';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { useGetRecommendations } from './use-get-recommendations';
import { useGetReadyToHireOffers } from './use-get-ready-to-hire-offers';

export const useGetAcquisitionOffers = () => {
  const { readyToHireOffers, isLoading: isLoadingReadyToHireOffers } =
    useGetReadyToHireOffers();

  const {
    offers: allowedOffersByActiveSubscription,
    isLoading: isLoadingGetAllowedOffersByActiveSubscription,
  } = useGetAllowedOffersByActiveSubscription();

  const {
    enabled: isRecommendationsSectionEnabled,
    payload: flagPayloadOffers,
  } = useFlag({
    flagName: 'FLASH_OS_APP_STORE',
    variant: true,
  });

  const {
    recommendations: acquisitionManagementOffers,
    isLoading: isLoadingRecommendations,
  } = useGetRecommendations();

  const isLoading =
    isLoadingGetAllowedOffersByActiveSubscription ||
    isLoadingRecommendations ||
    isLoadingReadyToHireOffers;

  let offers: string[] = [];

  if (acquisitionManagementOffers) {
    offers = acquisitionManagementOffers.offerIds;
  } else if (isRecommendationsSectionEnabled) {
    offers = flagPayloadOffers?.value
      ? JSON.parse(flagPayloadOffers.value)
      : ([] as string[]);
  }
  return {
    offers: offers.filter(
      (offer) =>
        allowedOffersByActiveSubscription?.includes(offer) &&
        !readyToHireOffers?.includes(offer),
    ),
    isLoading,
  };
};
