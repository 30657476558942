import {
  Typography,
  IconsProps,
  Button,
} from '@flash-tecnologia/hros-web-ui-v2';

import { RoundIcon } from '../../../RoundIcon';
import { FeatureProps, Feature } from '../Feature';
import { Tag, TagProps } from '../Tag';
import {
  CardButtonsContainer,
  CardButtonsInnerContainer,
  CardContainer,
  CardPlanInfoContainer,
  FeaturesContainer,
  FeeTagContainer,
  IconContainer,
} from './card.styles';
import { useTranslation } from 'react-i18next';

export interface CardDataProps {
  iconName: string;
  darkIcon?: boolean;
  planName: string;
  planDescription: string;
  fee?: string;
  tag?: TagProps;
  features: FeatureProps[];
  featuresDescription?: string;
  marketPageId: string;
  contracted?: boolean;
}

interface CardProps extends CardDataProps {
  onButtonClick: (marketPageId: string) => void;
}

export const Card = (props: CardProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.cardsSection.cards',
  });
  return (
    <CardContainer>
      <CardPlanInfoContainer>
        <IconContainer>
          <RoundIcon
            iconName={props.iconName as IconsProps['name']}
            size={24}
            iconColor={
              props.darkIcon
                ? 'var(--color-neutral-90)'
                : 'var(--color-secondary-50)'
            }
            backgroundColor={
              props.darkIcon
                ? 'var(--color-neutral-30)'
                : 'var(--color-secondary-90)'
            }
          />
          {props.tag && <Tag {...props.tag} />}
        </IconContainer>
        <Typography
          color="secondary.50"
          variant="headline9"
          style={{ textAlign: 'left' }}
        >
          {props.planName}
        </Typography>
        <Typography
          color="neutral.30"
          variant="body4"
          style={{ textAlign: 'left' }}
        >
          {props.planDescription}
        </Typography>
      </CardPlanInfoContainer>
      {props.fee && (
        <FeeTagContainer>
          <Typography
            color="secondary.50"
            variant="caption"
            style={{ textAlign: 'center' }}
            weight={600}
          >
            {props.fee}
          </Typography>
        </FeeTagContainer>
      )}
      {props.featuresDescription && (
        <Typography
          color="neutral.30"
          variant="caption"
          style={{ textAlign: 'left' }}
        >
          {props.featuresDescription}
        </Typography>
      )}
      <FeaturesContainer longList={props.features.length > 3}>
        {props.features.map((feature) => (
          <Feature iconName={feature.iconName} text={feature.text} />
        ))}
      </FeaturesContainer>
      <CardButtonsContainer>
        <CardButtonsInnerContainer>
          <Button
            data-onboarding={`app_store_plan_cta__${props.marketPageId}`}
            onClick={() => props.onButtonClick(props.marketPageId)}
            variant="primary"
          >
            {t('cta')}
          </Button>
        </CardButtonsInnerContainer>
      </CardButtonsContainer>
    </CardContainer>
  );
};
