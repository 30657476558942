import {
  usePermissions,
  useSelectedCompany,
} from '@flash-tecnologia/hros-web-utility';

export const useIsAllowedToAccessAcquisition = () => {
  const permissions = usePermissions();
  const { selectedCompany } = useSelectedCompany();

  return (
    permissions.isAdmin ||
    permissions.companies
      .find((company) => company.id === selectedCompany.id)
      ?.permissions?.includes('core_contract_products')
  );
};
