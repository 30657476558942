import {
  Typography,
  Icons,
  IconsProps,
} from '@flash-tecnologia/hros-web-ui-v2';
import { FeatureContainer, FeatureIconContainer } from './feature.styles';

export interface FeatureProps {
  iconName: string;
  text: string;
}
export const Feature = (props: FeatureProps) => {
  return (
    <FeatureContainer>
      <FeatureIconContainer>
        <Icons
          name={props.iconName as IconsProps['name']}
          size={12}
          color="var(--color-secondary-50)"
        />
      </FeatureIconContainer>
      <Typography
        color="neutral.30"
        variant="caption"
        style={{ textAlign: 'left' }}
      >
        {props.text}
      </Typography>
    </FeatureContainer>
  );
};
