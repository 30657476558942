import styled from 'styled-components';

export const Image = styled.img`
  height: 290px;
  width: auto;
  object-fit: cover;
  @media (max-width: 1024px) {
    width: 0;
  }
`;

export const MainBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 290px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  justify-content: space-between;
  align-items: center;
`;

export const MainBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  width: 55%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
