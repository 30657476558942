import { MarketplacePageContainer, PageContainer } from './app-store.styles';
import { useEffect, useState } from 'react';
import {
  AccessButtons,
  BannersSection,
  CardsSection,
  MainBanner,
  PageDivider,
  TabSelector,
} from './components';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { useGetAppStoreData } from './hooks/use-get-app-store-data';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { trackPage } from '@/utils/tracking';
import { MarketplacePage } from '@flash-hros/marketplace';
import { AppStoreData } from 'bff/src/constants/app-store/app-store-data.dto';

export const AppStorePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { appStoreData, isLoading: appStoreDataLoading } = useGetAppStoreData();
  const isFeaturedSectionEnabled = useFlag({
    flagName: 'FLASH_OS_APP_STORE_FEATURED_SECTION',
  });

  useEffect(() => {
    trackPage(`acquisition_app_store_page`);
  }, []);

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };

  const switchToMarketplaceTab = (data: AppStoreData) => {
    const marketplaceTabIndex = data.findIndex(
      (data) => data.name === 'marketplace',
    );
    setSelectedTab(marketplaceTabIndex);
  };

  if (appStoreDataLoading || !appStoreData) {
    return (
      <PageContainer>
        <Skeleton variant="rounded" height={320} />
      </PageContainer>
    );
  }
  const filteredData = appStoreData;
  filteredData[0].banners = filteredData[0].banners.map((banner) => ({
    ...banner,
    ...(banner.marketPageId === 'marketplace_banner' && {
      onButtonClick: () => switchToMarketplaceTab(filteredData),
    }),
  }));
  // if (!isFeaturedSectionEnabled) {
  //   filteredData = appStoreData.filter((data) => data.name !== 'featured');
  // }
  const tabs = filteredData.map((data, index) => ({
    ...data.tabData,
    isSelected: index === selectedTab,
  }));

  const selectedData = filteredData[selectedTab];
  const bannersData = selectedData.banners;
  const isMarketplace = tabs[selectedTab].id === 'marketplace_tab';

  return (
    <PageContainer>
      <MainBanner />
      <TabSelector tabs={tabs} onSelectTab={handleSelectTab} />
      {isMarketplace && (
        <MarketplacePageContainer>
          <MarketplacePage shouldDisplayHeader={false} />
        </MarketplacePageContainer>
      )}
      <BannersSection banners={bannersData} />
      <CardsSection data={selectedData.categories} />
      <PageDivider />
      <AccessButtons />
    </PageContainer>
  );
};
