import styled from 'styled-components';

export const BannerTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacings.xs5};
  border: 1px solid ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.l};
  padding: ${({ theme }) => `${theme.spacings.xs5} ${theme.spacings.xs3}`};
  align-items: center;
  width: fit-content;
`;
