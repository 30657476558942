import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacings.xl5};
  position: relative;
`;

export const MarketplacePageContainer = styled.div`
  padding-top: ${({ theme }) => theme.spacings.l};
`;
