import { useEffect } from 'react';
import {
  Breadcrumbs,
  Icons,
  LinkButton,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, StyledPageContainer } from './compare.styles';
import { SummarySection } from '@/components/SummarySection';
import { OffersComparative } from '@/components/OffersComparative';
import { ResourcesSection } from '@/components/ResourcesSection';
import { ConsultantContact } from '@/components/ConsultantContact';
import { SalesContactModal } from '@/components/Modals/SalesContactModal';
import { trackPage } from '@/utils/tracking';
import { dispatchToast } from '@/utils';
import { useTranslation } from 'react-i18next';
import { useGetMarketPage } from '@/shared/hooks';
import { LoadingState } from '@/components';

export const ComparePage = () => {
  const [t] = useTranslation('translations', { keyPrefix: 'acquisition' });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const id = params.get('id') || '';
  const { marketPage, isLoading, error } = useGetMarketPage(id);

  useEffect(() => {
    trackPage('acquisition_compare_page', { id });
  }, []);

  useEffect(() => {
    if (error) {
      dispatchToast({
        type: 'error',
        content: 'Ofertas não encontradas.',
      });
      navigate(-1);
    }
  }, [error]);

  const onInitialPageClick = () => {
    navigate('/home');
  };

  if (isLoading) {
    return <LoadingState />;
  }

  const breadcrumbs = [
    <LinkButton key="1" variant="neutral" onClick={onInitialPageClick}>
      {t('breadcrumb.initialPage')}
    </LinkButton>,
    <Typography key="2" variant="body4" weight={700} color="neutral.40">
      {t('breadcrumb.comparePrefix')} {marketPage?.title}
    </Typography>,
  ];

  return (
    <StyledPageContainer>
      <Breadcrumbs
        breadcrumbs={breadcrumbs}
        separator={<Icons name="IconChevronRight" />}
        style={{ height: 24 }}
      />
      <Container>
        <SummarySection marketPageId={id} />
        <OffersComparative marketPageId={id} />
        <ResourcesSection marketPageId={id} showOffersIncludedInfo />
        <ConsultantContact marketPageId={id} />
      </Container>
      <SalesContactModal />
    </StyledPageContainer>
  );
};
