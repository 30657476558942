import styled from 'styled-components';

export const MAIN_BANNER_HEIGHT = 150;
export const Image = styled.img`
  height: ${MAIN_BANNER_HEIGHT * 1.2}px;
  width: auto;
  object-fit: cover;
`;

export const MainBannerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${MAIN_BANNER_HEIGHT}px;
  background-color: ${({ theme }) => theme.colors.secondary[95]};
  justify-content: space-between;
  clip-path: border-box;
`;

export const MainBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  justify-content: center;
  width: 55%;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
