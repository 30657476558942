export default {
  translations: {
    appStore: {
      mainBanner: {
        title: 'Ofertas Flash',
      },
      bannersSection: {
        title: 'Novidades e destaques',
        learnMore: 'Saiba mais',
        freeTest: 'Teste gratuitamente',
      },
      cardsSection: {
        cards: {
          cta: 'Ver mais detalhes',
        },
      },
      accessButtons: {
        title: 'Gerencie produtos ativos',
        buttons: {
          manageProducts: {
            title: 'Gerencie produtos contratados',
            description: 'Acesse planos e contratos da sua empresa',
          },
          managePayments: {
            title: 'Gerencie pagamentos e cobranças',
            description: 'Acesse os pagamentos e cobranças da sua empresa',
          },
        },
      },
    },
    offersRecommendations: {
      recommendationsSectionTitle: 'Produtos recomendados para você',
      productsSectionTitle: 'Novos produtos',
      peopleProduct: {
        title: 'Gestão de Pessoas',
        description:
          'Impulsione sua empresa com uma gestão inteligente dos processos de admissão, treinamento, desenvolvimento e engajamento dos colaboradores.',
        admissionTopic: 'Admissão digital e Organograma',
        trainingTopic: 'Treinamentos e Engajamento',
        performanceTopic: 'Performance e People Analytics',
        actionText: 'Saiba mais',
      },
    },
    acquisition: {
      breadcrumb: {
        initialPage: 'Página inicial',
        comparePrefix: 'Comparar',
        contract: 'Contratação',
        appStore: 'Ofertas Flash',
      },
      summarySection: {
        contractButton: 'Contrate agora',
        compareButton: 'Comparar planos',
      },
      compareSection: {
        title: 'Comparar ofertas',
        bestOptionLabel: 'Melhor custo-benefício',
        contractText: 'Contrate agora',
        includedResources: 'Recursos inclusos',
        knownMore: 'Saiba mais sobre os recursos',
      },
      commercialContentSection: {
        title: 'Vantagens para sua empresa',
      },
      resourcesSection: {
        title: 'Ferramentas inclusas',
      },
      talkToConsultant: 'Contrate agora',
    },
    contract: {
      breadcrumb: {
        initialPage: 'Página inicial',
        contract: 'Contratação',
      },
      title: 'Contratação',
      exitText: 'Sair',
      cancelText: 'Cancelar',
      backText: 'Voltar',
      commercialConditionsStep: {
        title: 'Resumo das condições comerciais',
        conditionsEndDateText: 'Vigência do contrato até {{endDate}}',
        viewContractText: 'Ver contrato completo',
        readContractText: 'Li e aceito as condições comerciais do',
        nextText: 'Continuar',
      },
      reviewStep: {
        title: 'Revisão da compra',
        productText:
          'Revise as informações referentes à sua compra antes de prosseguir para a etapa de pagamento.',
        resumeText: 'Resumo da compra:',
        companyText: 'Empresa contratante:',
        productSelectedText: 'Planos selecionados:',
        nextText: 'Confirmar compra',
      },
      successStep: {
        title: 'Compra realizada com sucesso!',
        description:
          'O seu produto está ativo e pronto para ser utilizado. As informações abaixo também estão disponíveis no seu e-mail de administrador cadastrado.',
        nextText: 'Entendi',
        productBlock: {
          title: 'Acesse seu produto pelo menu lateral',
          description:
            'Seu produto pode ser acessado pelo menu lateral esquerdo na plataforma.',
          buttonText: 'Começar',
        },
        contractBlock: {
          title: 'Acesse seu contrato em configurar empresa',
          description:
            'Para gerenciar o contrato do produto, acesse as "Configurações" no menu lateral e "Planos e Contratos".',
          buttonText: 'Ir para a página de contratos',
        },
      },
    },
    consultantContact: {
      headline: 'Dúvidas? Fale com um consultor',
      text: 'Entre em contato com a nossa equipe comercial para tirar dúvidas ou negociar condições especiais. Nosso time está sempre pronto para te atender!',
      callToAction: 'Falar com consultor',
    },
    salesContactModal: {
      cancelButton: 'Cancelar',
      confirmButton: 'Enviar',
      successTitle: 'Informações enviadas com sucesso!',
      contactText:
        'Em breve um de nossos especialistas entrará em contato pelo e-mail flavia.mingo@flashapp.com.br ou pelo telefone (11)9900-9900.',
      contactWindowText: 'O contato será feito em dia útil, entre as 9h e 18h.',
      finishButton: 'Entendi',
    },
  },
};
