import {
  IconTypes,
  Icons,
  Tag,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Header } from './card.styles';

interface CardProps {
  icon: IconTypes;
  headline: string;
  description: string;
  tag?: string;
}

export const Card = ({ icon, headline, description, tag }: CardProps) => {
  return (
    <Container>
      <Header>
        <Icons
          name={icon}
          color="var(--color-primary)"
          size={40}
          style={{ flexShrink: 0 }}
        />
        {!!tag && (
          <Tag variant="pink" disabled style={{ alignSelf: 'center' }}>
            {tag}
          </Tag>
        )}
      </Header>
      <Typography variant="headline8" color="brand.50">
        {headline}
      </Typography>
      <Typography variant="body4" color="neutral.30">
        {description}
      </Typography>
    </Container>
  );
};
