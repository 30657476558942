import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BannerContainer,
  BannerImage,
  Button,
  PrimaryBannerContentContainer,
  PrimaryBannerInnerContentContainer,
  PrimaryBannerTextContainer,
} from './primary-banner.styles';
import { BannerTag } from '../BannerTag';
import { BannerProps } from '../banners-section.dto';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { trackEvent } from '@/utils/tracking';

export interface MainBannerProps extends BannerProps {
  contentStyle: {
    width: string;
  };
  imageStyle?: React.CSSProperties;
  buttonText: string;
  marketPageId: string;
  onButtonClick?: VoidFunction;
  dataOnboarding?: string;
}

export const PrimaryBanner = (props: MainBannerProps) => {
  const navigate = useNavigate();
  const onButtonClick = () => {
    if (props.onButtonClick) {
      props.onButtonClick();
      return;
    }
    trackEvent('acquisition_app_store_banner_clicked', {
      marketPageId: props.marketPageId,
    });
    const params = createSearchParams(location.search);
    params.set('origin', 'app_store_banner');
    params.set('id', props.marketPageId);
    navigate({ pathname: '/acquisition/overview', search: params.toString() });
  };
  return (
    <BannerContainer style={{ backgroundColor: props.backgroundColor }}>
      <PrimaryBannerContentContainer contentWidth={props.contentStyle.width}>
        <PrimaryBannerInnerContentContainer>
          {!!props.tagProps && (
            <BannerTag
              icon={props.tagProps.iconName}
              text={props.tagProps.text}
            />
          )}
          <PrimaryBannerTextContainer>
            <Typography color="neutral.20" variant="headline7" weight={700}>
              {props.title}
            </Typography>
            <Typography
              color="neutral.30"
              variant="body4"
              style={{
                WebkitLineClamp: 3,
                textOverflow: 'ellipsis',
              }}
            >
              {props.description}
            </Typography>
          </PrimaryBannerTextContainer>
        </PrimaryBannerInnerContentContainer>
        <Button
          onClick={onButtonClick}
          variant="primary"
          data-onboarding={props.dataOnboarding}
        >
          {props.buttonText}
        </Button>
      </PrimaryBannerContentContainer>
      <BannerImage image={props.image} style={props.imageStyle} />
    </BannerContainer>
  );
};
