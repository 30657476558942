import { Tag, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  Container,
  Content,
  DetailItem,
  DetailsContent,
  Icons,
  LineDecoration,
  OffersContainer,
  OffersInclusion,
  TextsContainer,
} from './resource.styles';

interface ResourceProps {
  name: string;
  description: string;
  details: string[];
  previewImagePath?: string;
  offers?: {
    included: string[];
    notIncluded: string[];
  };
  showOffersIncludedInfo?: boolean;
}

export const Resource = ({
  name,
  description,
  details,
  previewImagePath,
  offers = { included: [], notIncluded: [] },
  showOffersIncludedInfo = false,
}: ResourceProps) => {
  return (
    <Container>
      {previewImagePath && (
        <img src={previewImagePath} alt="resource screenshot" />
      )}
      <Content>
        <TextsContainer>
          <Typography variant="body3" color="brand.50" weight={700}>
            {name}
          </Typography>
          <Typography variant="headline8" style={{ whiteSpace: 'pre-line' }}>
            {description}
          </Typography>
          <LineDecoration />
          {showOffersIncludedInfo && (
            <OffersInclusion>
              <Typography variant="body4" weight={600}>
                Recurso presente nas seguintes ofertas:
              </Typography>
              {offers.included.length > 0 && (
                <OffersContainer>
                  {offers.included.map((offerName) => (
                    <Tag variant="primary" disabled>
                      <Icons name="IconCheck" size={16} fill="transparent" />
                      {offerName}
                    </Tag>
                  ))}
                </OffersContainer>
              )}
              {offers.notIncluded.length > 0 && (
                <OffersContainer>
                  {offers.notIncluded.map((offerName) => (
                    <Tag variant="gray" disabled>
                      <Icons name="IconX" size={16} />
                      {offerName}
                    </Tag>
                  ))}
                </OffersContainer>
              )}
            </OffersInclusion>
          )}
        </TextsContainer>
        {details.length > 0 && (
          <DetailsContent>
            {details.map((detail, i) => (
              <DetailItem key={'detail-item' + i}>
                <Icons
                  name="IconArrowNarrowRight"
                  color="var(--color-secondary-50)"
                  size={16}
                  height={20}
                  strokeWidth="1.5"
                  style={{ flexShrink: 0 }}
                />
                <Typography variant="body4" color="neutral.20">
                  {detail}
                </Typography>
              </DetailItem>
            ))}
          </DetailsContent>
        )}
      </Content>
    </Container>
  );
};
