import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  SecondaryBannersContainer,
  BannersContainer,
  BannersSectionContainer,
  PageContainer,
} from './layout-3.styles';
import { PrimaryBanner } from '../PrimaryBanner';
import { BannerProps } from '../banners-section.dto';
import { SecondaryBanner } from '../SecondaryBanner';
import { useTranslation } from 'react-i18next';

export interface BannersLayout3Props {
  banners: BannerProps[];
}

export const BannersLayout3 = (props: BannersLayout3Props) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.bannersSection',
  });
  return (
    <BannersSectionContainer>
      <PageContainer>
        <Typography
          color="secondary.50"
          variant="headline9"
          style={{ marginBottom: '12px' }}
        >
          {t('title')}
        </Typography>
        <BannersContainer>
          <PrimaryBanner
            contentStyle={{ width: '50%' }}
            imageStyle={{ height: '100%' }}
            dataOnboarding="app_store_first_banner"
            {...props.banners[0]}
            backgroundColor="var(--color-secondary-90)"
          />
          <SecondaryBannersContainer>
            <SecondaryBanner
              dataOnboarding="app_store_second_banner"
              {...props.banners[1]}
              buttonText={t('learnMore')}
              backgroundColor="#C6EEFD"
            />
            <SecondaryBanner
              dataOnboarding="app_store_third_banner"
              {...props.banners[2]}
              buttonText={t('learnMore')}
              backgroundColor="#F0F2F2"
            />
          </SecondaryBannersContainer>
        </BannersContainer>
      </PageContainer>
    </BannersSectionContainer>
  );
};
