import {
  Icons,
  IconsProps,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { BannerTagContainer } from './banner-tag.styles';

export const BannerTag = (props: { icon: string; text: string }) => {
  return (
    <BannerTagContainer>
      <Icons
        name={props.icon as IconsProps['name']}
        color="var(--color-secondary-50)"
        size={16}
      />
      <Typography variant="caption" color="secondary.50">
        {props.text}
      </Typography>
    </BannerTagContainer>
  );
};
