import { Typography } from '@flash-tecnologia/hros-web-ui-v2';
import {
  BannersContainer,
  BannersSectionContainer,
  PageContainer,
} from './layout-2.styles';
import { PrimaryBanner } from '../PrimaryBanner';
import { BannerProps } from '../banners-section.dto';
import { useTranslation } from 'react-i18next';

export interface BannersLayout2Props {
  banners: [BannerProps, BannerProps];
}

const bannerImageStyles: React.CSSProperties = {
  height: '90%',
};

export const BannersLayout2 = (props: BannersLayout2Props) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'appStore.bannersSection',
  });
  return (
    <BannersSectionContainer>
      <PageContainer>
        <Typography
          color="secondary.50"
          variant="headline9"
          style={{ marginBottom: '12px' }}
        >
          {t('title')}
        </Typography>
        <BannersContainer>
          <PrimaryBanner
            contentStyle={{ width: '50%' }}
            imageStyle={bannerImageStyles}
            backgroundColor="var(--color-secondary-90)"
            dataOnboarding="app_store_first_banner"
            {...props.banners[0]}
          />
          <PrimaryBanner
            contentStyle={{ width: '50%' }}
            imageStyle={bannerImageStyles}
            backgroundColor="#C6EEFD"
            dataOnboarding="app_store_second_banner"
            {...props.banners[1]}
          />
        </BannersContainer>
      </PageContainer>
    </BannersSectionContainer>
  );
};
