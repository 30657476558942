import { IconTypes, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PageContainer, SectionBlocks } from './recommendations-section.styles';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import { Product } from '../Product';
import { trackEvent } from '@/utils/tracking';
import { OfferCard } from '../OfferCard';
import { useGetAcquisitionOffers } from './hooks/use-get-acquisition-offers';
import { OfferRecommendationMap } from 'bff/src/constants/offers';

export const RecommendationsSection = () => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'offersRecommendations',
  });
  const navigate = useNavigate();

  const { offers, isLoading } = useGetAcquisitionOffers();

  const onPeopleProductClick = () => {
    trackEvent('home_people_product_know_more_clicked');
    navigate('/flows/hiring');
  };

  const onRecommendationsClick = (marketPageId: string) => {
    trackEvent('acquisition_offers_recommendation_know_more_clicked', {
      market_page_id: marketPageId,
    });
    const params = createSearchParams(location.search);
    params.set('id', marketPageId);
    params.set('origin', 'home_recommendation');
    navigate({ pathname: '/acquisition/overview', search: params.toString() });
  };

  if (isLoading) {
    return (
      <PageContainer>
        <Skeleton variant="rounded" height={320} />
      </PageContainer>
    );
  }

  if (!offers.length) {
    return (
      <PageContainer>
        <Typography variant="headline7" style={{ marginBottom: '20px' }}>
          {t('productsSectionTitle')}
        </Typography>
        <SectionBlocks>
          <Product
            icon="IconUsers"
            title={t('peopleProduct.title')}
            text={t('peopleProduct.description')}
            topics={[
              ['IconUserPlus', t('peopleProduct.admissionTopic')],
              ['IconMessageDots', t('peopleProduct.trainingTopic')],
              ['IconRocket', t('peopleProduct.performanceTopic')],
            ]}
            actionText={t('peopleProduct.actionText')}
            onClick={onPeopleProductClick}
          />
        </SectionBlocks>
      </PageContainer>
    );
  }

  const recommendationOffers = offers
    .map((offer) => OfferRecommendationMap[offer])
    .filter(Boolean);

  if (!recommendationOffers.length) {
    return null;
  }

  return (
    <PageContainer>
      <Typography variant="headline7" style={{ marginBottom: '20px' }}>
        {t('recommendationsSectionTitle')}
      </Typography>
      <SectionBlocks>
        {recommendationOffers.map((offer) => (
          <OfferCard
            key={offer.marketPageId}
            imageUrl={offer.imageUrl}
            title={offer.title}
            text={offer.description}
            actionText={offer.ctaText}
            topics={offer.topics.map(({ icon, text }) => ({
              icon: icon as IconTypes,
              text,
            }))}
            onClick={() => onRecommendationsClick(offer.marketPageId)}
          />
        ))}
      </SectionBlocks>
    </PageContainer>
  );
};
