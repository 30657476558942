import styled from 'styled-components';

export const BannersSectionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.neutral[95]}`};
  padding: ${({ theme }) => theme.spacings.l} 0;
`;

export const BannersContainer = styled.div`
  display: grid;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
  grid-template-columns: repeat(2, minmax(min-content, max-content));
  @media (max-width: 1024px) {
    grid-template-columns: repeat(1, minmax(min-content, max-content));
  }
`;

export const SecondaryBannersContainer = styled.div`
  display: flex;
  height: 279px;
  flex: 4;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs3};
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1029px;
`;
