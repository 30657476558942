import { MarketplacePageContainer, PageContainer } from './app-store.styles';
import { useEffect, useState } from 'react';
import {
  AccessButtons,
  BannersSection,
  CardsSection,
  MainBanner,
  PageDivider,
  TabSelector,
} from './components';
import { useGetAppStoreData } from './hooks/use-get-app-store-data';
import { Skeleton } from '@flash-tecnologia/hros-web-ui-v2';
import { trackPage } from '@/utils/tracking';
import { MarketplacePage } from '@flash-hros/marketplace';
import { AppStoreData } from 'bff/src/constants/app-store/app-store-data.dto';
import { useIsAllowedToAccessAcquisition } from '@/shared/hooks/use-is-allowed-to-access-acquisition';
import { useNavigate } from 'react-router-dom';

export const AppStorePage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const navigate = useNavigate();
  const { appStoreData, isLoading: appStoreDataLoading } = useGetAppStoreData();
  const isAllowedToAccessAcquisition = useIsAllowedToAccessAcquisition();

  useEffect(() => {
    trackPage(`acquisition_app_store_page`);
  }, []);

  useEffect(() => {
    if (!isAllowedToAccessAcquisition) {
      navigate('/home');
    }
  }, [isAllowedToAccessAcquisition, navigate]);

  const handleSelectTab = (index: number) => {
    setSelectedTab(index);
  };

  const switchToMarketplaceTab = (data: AppStoreData) => {
    const marketplaceTabIndex = data.findIndex(
      (data) => data.name === 'marketplace',
    );
    setSelectedTab(marketplaceTabIndex);
  };

  if (appStoreDataLoading || !appStoreData) {
    return (
      <PageContainer>
        <Skeleton variant="rounded" height={320} />
      </PageContainer>
    );
  }
  const filteredData = appStoreData;
  filteredData[0].banners = filteredData[0].banners.map((banner) => ({
    ...banner,
    ...(banner.marketPageId === 'marketplace_banner' && {
      onButtonClick: () => switchToMarketplaceTab(filteredData),
    }),
  }));

  const tabs = filteredData.map((data, index) => ({
    ...data.tabData,
    isSelected: index === selectedTab,
  }));

  const selectedData = filteredData[selectedTab];
  const bannersData = selectedData.banners;
  const isMarketplace = tabs[selectedTab].id === 'marketplace_tab';

  return (
    <PageContainer>
      <MainBanner />
      {tabs.length > 1 && (
        <TabSelector tabs={tabs} onSelectTab={handleSelectTab} />
      )}
      {isMarketplace && (
        <MarketplacePageContainer>
          <MarketplacePage shouldDisplayHeader={false} />
        </MarketplacePageContainer>
      )}
      <BannersSection banners={bannersData} />
      <CardsSection data={selectedData.cardGroups} />
      <PageDivider />
      <AccessButtons />
    </PageContainer>
  );
};
