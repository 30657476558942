import { Button as BaseButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const PrimaryBannerContentContainer = styled.div<{
  contentWidth: string;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: ${({ contentWidth }) => contentWidth};
  padding: ${({ theme }) =>
    `${theme.spacings.xs} 0 ${theme.spacings.xs} ${theme.spacings.s}`};
`;

export const PrimaryBannerInnerContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
  justify-content: flex-start;
`;

export const PrimaryBannerTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs5};
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  height: 274px;
  min-width: 490px;
  flex-direction: row;
  align-items: flex-end;
  gap: ${({ theme }) => theme.spacings.xs4};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  position: relative;
  overflow: hidden;
  max-width: 649px;
`;

export const Button = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;

export const BannerImage = styled.div<{ image: string }>`
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
  background-image: ${({ image }) => `url(${image})`};
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: left bottom;
`;
