import { useGetAcquisitionOffers } from '@/components/RecommendationsSection/hooks/use-get-acquisition-offers';
import { AppStoreData } from 'bff/src/constants/app-store/app-store-data.dto';
import {
  benefitsBanner,
  benefitsBasic,
  expenseBanner,
  expenseBannerV202501,
  expenseBasic,
  expenseBasicv202501,
  marketplaceBanner,
  rhFull,
  rhFullBanner,
} from 'bff/src/constants/app-store/offers';

const baseAppStoreData: AppStoreData = [
  {
    name: 'featured',
    tabData: {
      id: 'featured_tab',
      title: 'Destaques',
      icon: 'IconStar',
    },
    banners: [],
    cardGroups: [
      {
        backgroundImage:
          'https://images.flashapp.com.br/flash-os/acquisition/app-store/Groups/benefits.png',
        plansInfo: {
          title: 'Planos em destaque',
          description: 'Confira abaixo os planos que destacamos para você',
        },
        cards: [],
      },
      {
        backgroundImage:
          'https://images.flashapp.com.br/flash-os/acquisition/app-store/Groups/people.png',
        plansInfo: {
          title: 'Planos de gestão de pessoas',
          description: 'confira nossos planos voltados para o seu RH',
        },
        cards: [],
      },
    ],
  },
  {
    name: 'hr',
    tabData: {
      id: 'hr_tab',
      title: 'Soluções para o RH',
      icon: 'IconShoppingCart',
    },
    banners: [],
    cardGroups: [
      {
        plansName: 'Gestão de benefícios',
        cards: [],
      },
      {
        plansName: 'Gestão de pessoas',
        cards: [],
      },
    ],
  },
  {
    name: 'expense',
    tabData: {
      id: 'expense_tab',
      title: 'Soluções para o financeiro',
      icon: 'IconUsers',
    },
    banners: [],
    cardGroups: [
      {
        plansName: 'Gestão de Despesas',
        cards: [],
      },
    ],
  },
  {
    name: 'marketplace',
    tabData: {
      id: 'marketplace_tab',
      title: 'Parceiros Flash',
      icon: 'IconHeartHandshake',
    },
    banners: [],
    cardGroups: [],
  },
];
const filterEmptyTabs = (appStoreData: AppStoreData) => {
  return appStoreData.filter(
    (tab) =>
      !tab.cardGroups.every((group) => group.cards.length === 0) ||
      tab.banners.length > 0 ||
      tab.name === 'marketplace',
  );
};

export const useGetAppStoreData = () => {
  const { offers, isLoading: isLoadingOffers } = useGetAcquisitionOffers();
  const data = structuredClone(baseAppStoreData);
  if (offers.includes(benefitsBasic.marketPageId)) {
    // featured section
    data[0].cardGroups[0].cards.push(benefitsBasic);
    data[0].banners.push(benefitsBanner);
    //hr section
    data[1].cardGroups[0].cards.push(benefitsBasic);
  }

  if (offers.includes(rhFull.marketPageId)) {
    // featured section
    data[0].cardGroups[0].cards.push(rhFull);
    data[0].banners.push(rhFullBanner);
    //hr section
    data[1].cardGroups[1].cards.push(rhFull);
  }

  if (offers.includes(expenseBasicv202501.marketPageId)) {
    // featured section
    data[0].cardGroups[0].cards.push(expenseBasicv202501);
    data[0].banners.push(expenseBannerV202501);
    //expense section
    data[2].cardGroups[0].cards.push(expenseBasicv202501);
  }

  if (offers.includes(expenseBasic.marketPageId)) {
    // featured section
    data[0].cardGroups[0].cards.push(expenseBasic);
    data[0].banners.push(expenseBanner);
    //expense section
    data[2].cardGroups[0].cards.push(expenseBasic);
  }

  if (data[0].banners.length) {
    data[0].banners.push(marketplaceBanner);
  }

  return { appStoreData: filterEmptyTabs(data), isLoading: isLoadingOffers };
};
