import { Icons, IconsProps } from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Tag, Text } from './tab-label.styles';

export const TabLabel = ({
  icon,
  name,
  tag,
}: {
  icon: string;
  name: string;
  tag?: string;
}) => {
  return (
    <Container>
      <Icons
        name={icon as IconsProps['name']}
        size={24}
        strokeWidth="2"
        fill="transparent"
      />
      <Text>{name}</Text>
      {tag && (
        <Tag variant="pink" size="small">
          {tag}
        </Tag>
      )}
    </Container>
  );
};
