import { BannerProps, CardProps } from './app-store-data.dto';

export const benefitsBasic: CardProps = {
  iconName: 'IconShoppingCart',
  planName: 'Gestão básica de benefícios',
  marketPageId: 'sales_flash_benefits_basic',
  planDescription:
    'Para empresas que buscam oferecer os melhores e mais aceitos benefícios flexíveis para seus colaboradores',
  features: [
    { iconName: 'IconBasket', text: 'Alimentação' },
    { iconName: 'IconToolsKitchen2', text: 'Refeição' },
    {
      iconName: 'IconLayoutGridAdd',
      text: 'Multibenefícios: alimentação e refeição, auxílio mobilidade, saúde, educação, cultura, auxílio home office e premiação',
    },
  ],
};

export const rhOps: CardProps = {
  iconName: 'IconUserCheck',
  planName: 'Gestão Operacional de RH',
  marketPageId: 'sales_flash_rh_ops',
  planDescription:
    'Para empresas que querem simplificar a rotina operacional do RH e do DP com soluções para automatizar processos da admissão ao controle de ponto',
  features: [
    { iconName: 'IconLayoutKanban', text: 'Gestão de Fluxos' },
    { iconName: 'IconClockHour5', text: 'Controle de jornada' },
    { iconName: 'IconFolder', text: 'Gestão de Documentos' },
  ],
};

export const rhFull: CardProps = {
  iconName: 'IconChecklist',
  planName: 'Gestão Completa de RH',
  marketPageId: 'sales_flash_rh_full',
  planDescription:
    'Para empresas que buscam uma plataforma completa para centralizar toda a jornada do colaborador com soluções para admitir, capacitar e engajar',
  featuresDescription: 'Tudo do plano de Gestão Operacional, mais:',
  features: [
    { iconName: 'IconSchool', text: 'LMS' },
    { iconName: 'IconRocket', text: 'Performance' },
    { iconName: 'IconSpeakerphone', text: 'Engajamento' },
    { iconName: 'IconHierarchy', text: 'Organograma' },
    { iconName: 'IconChartPie', text: 'People Analytics' },
  ],
};

export const expenseBasic: CardProps = {
  iconName: 'IconCreditCard',
  darkIcon: true,
  planName: 'Gestão Básica de Despesas',
  marketPageId: 'sales_flash_expense_basic',
  planDescription:
    'Para empresas que desejam controlar gastos corporativos de maneira mais inteligente',
  features: [
    { iconName: 'IconCreditCard', text: 'Cartão Corporativo' },
    { iconName: 'IconPlaneTilt', text: 'Plataforma de Viagens' },
    {
      iconName: 'IconReceipt2',
      text: 'Plataforma de despesas Básicas: Prestação de contas e reembolso, Notificações, Aprovações automáticas, categorias das despesas, Relatórios em PDF',
    },
  ],
};

export const expenseBasicv202501: CardProps = {
  iconName: 'IconCreditCard',
  darkIcon: true,
  planName: 'Gestão Básica de Despesas',
  marketPageId: 'sales_flash_expense_basic_v202501',
  planDescription:
    'Para empresas que desejam controlar gastos corporativos de maneira mais inteligente',
  features: [
    { iconName: 'IconCreditCard', text: 'Cartão Corporativo' },
    { iconName: 'IconPlaneTilt', text: 'Plataforma de Viagens' },
    {
      iconName: 'IconReceipt2',
      text: 'Plataforma de despesas Básicas: Prestação de contas e reembolso, Notificações, Aprovações automáticas, categorias das despesas, Relatórios em PDF',
    },
  ],
};

export const benefitsBanner: BannerProps = {
  backgroundColor: '#FFCCE4',
  buttonText: 'Quero conhecer!',
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/benefits-banner.png',
  title: 'Plano de Gestão de benefícios!',
  marketPageId: 'sales_flash_benefits_basic',
  tagProps: {
    iconName: 'IconNorthStar',
    text: 'Novidade!',
  },
  description:
    'Para empresas que buscam oferecer os melhores benefícios flexíveis para seus colaboradores',
};

export const rhOpsBanner: BannerProps = {
  buttonText: 'Quero conhecer!',
  marketPageId: 'sales_flash_rh_ops',
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/secondary/woman-secondary-a.png',
  title: 'Gestão Operacional de RH',
  description: 'Centralize admissão, benefício e ponto em uma única plataforma',
  tagProps: {
    iconName: 'IconNorthStar',
    text: 'Novidade!',
  },
};

export const rhFullBanner: BannerProps = {
  buttonText: 'Quero conhecer!',
  marketPageId: 'sales_flash_rh_full',
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/secondary/woman-secondary-a.png',
  title: 'Gestão Completa de RH',
  description: 'Centralize toda a jornada do colaborador em uma plataforma',
  tagProps: {
    iconName: 'IconNorthStar',
    text: 'Novidade!',
  },
};

export const expenseBanner: BannerProps = {
  backgroundColor: '#C6EEFD',
  buttonText: 'Quero conhecer!',
  marketPageId: 'sales_flash_expense_basic',
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/expense-banner.png',
  title: 'Gestão de Despesas',
  description: 'Controle gastos corporativos de maneira mais inteligente',
  tagProps: {
    iconName: 'IconNorthStar',
    text: 'Novidade!',
  },
};

export const expenseBannerV202501: BannerProps = {
  backgroundColor: '#C6EEFD',
  buttonText: 'Quero conhecer!',
  marketPageId: 'sales_flash_expense_basic_v202501',
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/expense-banner.png',
  title: 'Gestão de Despesas',
  description: 'Controle gastos corporativos de maneira mais inteligente',
  tagProps: {
    iconName: 'IconNorthStar',
    text: 'Novidade!',
  },
};

export const marketplaceBanner: BannerProps = {
  backgroundColor: '#E9EBEB',
  buttonText: 'Quero conhecer',
  marketPageId: 'marketplace_banner', //TODO: 'marketplace_banner' is not defined
  image:
    'https://images.flashapp.com.br/flash-os/acquisition/app-store/banners/partners-banner.png',
  title: 'Parceiros Flash',
  description:
    'Multiplique os benefícios da sua empresa com parceiros exclusivos',
  tagProps: {
    iconName: 'IconHeartHandshake',
    text: 'Parceiros',
  },
};
