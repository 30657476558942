import { StyledPageContainer } from './cards-section.styles';

import { CardGroup, CardGroupProps } from './components/CardGroup';

export const CardsSection = (props: { data: CardGroupProps[] }) => {
  return (
    <StyledPageContainer>
      {props.data.map((cardGroup) => {
        if (!cardGroup.cards.length) {
          return null;
        }
        return <CardGroup {...cardGroup} />;
      })}
    </StyledPageContainer>
  );
};
