import cx from 'classnames';
import {
  Button,
  Icons,
  IconsProps,
  LinkButton,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  ButtonsContainer,
  Container,
  Content,
  Description,
  PriceWithPadding,
  ResourceKnowMoreButton,
  ResourceList,
  ResourcesContainer,
  TagSlot,
} from './offer-card.styles';
import { RouterOutputs } from '@/api/client';
import { ResourceItem } from '../ResourceItem';
import { useSalesContactModal } from '@/context/sales-contact-modal';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { trackEvent } from '@/utils/tracking';

interface OfferCardProps {
  marketPageId: string;
  offer: RouterOutputs['getMarketPage']['offers'][0];
}

export const OfferCard = ({ marketPageId, offer }: OfferCardProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'acquisition',
  });
  const navigate = useNavigate();
  const { openModal } = useSalesContactModal();

  const onSalesContactRequest = () => {
    trackEvent('acquisition_compare_offer_consultant_clicked', {
      market_page_id: marketPageId,
      offer_id: offer.id,
    });
    openModal(marketPageId, offer.id);
  };

  const onKnowMoreClick = () => {
    trackEvent('acquisition_compare_offer_know_more_clicked', {
      market_page_id: marketPageId,
      offer_id: offer.id,
    });

    document
      .getElementById('offer-resources')
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  const onHireClick = () => {
    trackEvent('acquisition_compare_offer_contract_clicked', {
      market_page_id: marketPageId,
      offer_id: offer.id,
    });

    const params = createSearchParams(location.search);
    params.set('offer_id', offer.id);
    navigate({ pathname: '/acquisition/contract', search: params.toString() });
  };

  return (
    <Container className={cx({ 'featured-plan': offer.featured })}>
      <TagSlot className={cx({ 'featured-plan': offer.featured })}>
        {offer.featured && (
          <>
            <Icons
              name="IconAward"
              size={16}
              height={22}
              color="var(--color-primary)"
              style={{ flexShrink: 0 }}
            />
            <Typography variant="caption" color="brand.50" weight={700}>
              {t('compareSection.bestOptionLabel')}
            </Typography>
          </>
        )}
      </TagSlot>
      <Content>
        <Description>
          <ShapeIcon
            name={offer.icon as IconsProps['name']}
            variant="default"
            color="var(--color-primary)"
            size={48}
            style={{ flexShrink: 0 }}
          />
          <Typography variant="headline7" color="neutral.30">
            {offer.name}
          </Typography>
          <Typography variant="body3" color="neutral.50">
            {offer.description}
          </Typography>
        </Description>
        {offer.price && (
          <PriceWithPadding
            value={offer.price.value}
            unit={offer.price.unit}
            valueWithDiscount={offer.price.valueWithDiscount}
          />
        )}
        <ButtonsContainer>
          <Button
            variant="primary"
            size="medium"
            onClick={onHireClick}
            style={{ width: '100%' }}
          >
            {t('compareSection.contractText')}
          </Button>
          <LinkButton
            variant="default"
            onClick={onSalesContactRequest}
            style={{ alignSelf: 'center' }}
          >
            {t('talkToConsultant')} <Icons name="IconArrowRight" />
          </LinkButton>
        </ButtonsContainer>
        <ResourcesContainer>
          <Typography variant="headline8" color="neutral.30">
            {t('compareSection.includedResources')}
          </Typography>
          <ResourceList>
            {offer.resources.map((resource, i) => (
              <ResourceItem
                key={i}
                icon={resource.icon}
                name={resource.name}
                subResources={resource.subResources}
              />
            ))}
          </ResourceList>
        </ResourcesContainer>
        <ResourceKnowMoreButton
          variant="secondary"
          size="medium"
          onClick={onKnowMoreClick}
        >
          {t('compareSection.knownMore')}
        </ResourceKnowMoreButton>
      </Content>
    </Container>
  );
};
