import {
  Button,
  IconTypes,
  Icons,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import { Container, Image, Section, Topic } from './offer-card.styles';

interface OfferCardProps {
  imageUrl: string;
  title: string;
  text: string;
  topics: {
    icon: IconTypes;
    text: string;
  }[];
  actionText: string;
  onClick: () => void;
}

export const OfferCard = ({
  title,
  text,
  topics,
  actionText,
  onClick,
  imageUrl,
}: OfferCardProps) => {
  return (
    <Container>
      <Image src={imageUrl} alt="foto promocional da oferta" />
      <Section>
        <Typography.Headline8 color="brand.50">{title}</Typography.Headline8>
        <Typography.Body3 color="neutral.50">{text}</Typography.Body3>
      </Section>
      <Section style={{ flexGrow: 1 }}>
        {topics.map(({ icon, text }, i) => (
          <Topic key={i}>
            <Icons
              name={icon}
              color="var(--color-primary)"
              fill="none"
              size={16}
            />
            <Typography.Body4 color="neutral.50">{text}</Typography.Body4>
          </Topic>
        ))}
      </Section>
      <Section>
        <Button size="small" variant="secondary" onClick={onClick}>
          {actionText} <Icons name="IconArrowRight" />
        </Button>
      </Section>
    </Container>
  );
};
