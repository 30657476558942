import {
  Icons,
  LinkButton,
  ShapeIcon,
  Typography,
} from '@flash-tecnologia/hros-web-ui-v2';
import {
  Container,
  Content,
  ContentItem,
  ContentItemContainer,
  Header,
} from './success-step.style';
import { useNavigate } from 'react-router-dom';
import { DividedContent } from '../DividedContent';
import { trackEvent } from '@/utils/tracking';
import { useTranslation } from 'react-i18next';

export const SuccessStep = () => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'contract.successStep',
  });
  const navigate = useNavigate();

  const onViewMenuClick = () => {
    trackEvent('acquisition_contract_success_step_view_menu_clicked');
    navigate('/home?showMenu=true');
  };

  const onContractPageClick = () => {
    trackEvent('acquisition_contract_success_step_view_contract_page_clicked');
    navigate('/contract-manager/contracts-and-amendments');
  };

  return (
    <DividedContent
      header="Confirmação de compra"
      description="O seu produto está ativo e pronto para ser utilizado."
    >
      <Container>
        <Header>
          <ShapeIcon
            name="IconCheck"
            variant="success"
            size={48}
            color="var(--color-feedback-success-40)"
            style={{ flexShrink: 0, marginBottom: 4 }}
          />
          <Typography variant="headline8" color="neutral.30">
            {t('title')}
          </Typography>
          <Typography variant="body3" color="neutral.50">
            {t('description')}
          </Typography>
        </Header>
        <Content>
          <ContentItemContainer>
            <ContentItem>
              <ShapeIcon
                name="IconClick"
                variant="default"
                size={40}
                color="var(--color-secondary-50)"
                style={{ flexShrink: 0 }}
              />
              <Typography variant="body3" color="neutral.30" weight={700}>
                {t('productBlock.title')}
              </Typography>
              <Typography variant="body4" color="neutral.40">
                {t('productBlock.description')}
              </Typography>
            </ContentItem>
            <LinkButton
              variant="default"
              onClick={onViewMenuClick}
              style={{ alignSelf: 'start' }}
            >
              <Typography variant="body4" weight={700}>
                {t('productBlock.buttonText')}
              </Typography>{' '}
              <Icons name="IconArrowRight" size={16} />
            </LinkButton>
          </ContentItemContainer>
          <ContentItemContainer>
            <ContentItem>
              <ShapeIcon
                name="IconSettings"
                variant="default"
                size={40}
                color="var(--color-secondary-50)"
                style={{ flexShrink: 0 }}
              />
              <Typography variant="body3" color="neutral.30" weight={700}>
                {t('contractBlock.title')}
              </Typography>
              <Typography variant="body4" color="neutral.40">
                {t('contractBlock.description')}
              </Typography>
            </ContentItem>
            <LinkButton
              variant="default"
              onClick={onContractPageClick}
              style={{ alignSelf: 'start' }}
            >
              <Typography variant="body4" weight={700}>
                {t('contractBlock.buttonText')}
              </Typography>{' '}
              <Icons name="IconArrowRight" size={16} />
            </LinkButton>
          </ContentItemContainer>
        </Content>
      </Container>
    </DividedContent>
  );
};
