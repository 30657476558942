import { trpc } from '@/api/client';
import { useSelectedCompany } from '@flash-tecnologia/hros-web-utility';

export const useGetAllowedOffersByActiveSubscription = () => {
  const { selectedCompany } = useSelectedCompany();
  const { data, isLoading } = trpc.getOffersRecommendations.useQuery(
    {
      companyId: selectedCompany.id,
    },
    { refetchOnWindowFocus: false },
  );

  return {
    offers: data?.map((offer) => offer.marketPageId),
    isLoading,
  };
};
