import { useSalesContactModal } from '@/context/sales-contact-modal';
import { trackEvent } from '@/utils/tracking';
import { Icons, Skeleton, Typography } from '@flash-tecnologia/hros-web-ui-v2';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';
import {
  Button,
  ButtonsContainer,
  Container,
  Content,
  Image,
  TextsContainer,
  PriceWithBorder,
} from './summary-section.styles';
import { useGetMarketPage } from '@/shared/hooks';

interface SummaryProps {
  marketPageId: string;
}

export const SummarySection = ({ marketPageId }: SummaryProps) => {
  const [t] = useTranslation('translations', {
    keyPrefix: 'acquisition',
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { openModal: openSalesModal } = useSalesContactModal();
  const { marketPage, isLoading } = useGetMarketPage(marketPageId);
  const isCompareUrl = !location.pathname.includes('compare');

  if (isLoading) {
    return <Skeleton variant="rounded" width="100%" height="500px" />;
  }

  if (!marketPage) {
    return null;
  }

  const hasMultipleOffers = marketPage?.offers.length > 1;
  const lowestPriceOffer = hasMultipleOffers
    ? marketPage.offers.sort(
        (a, b) => (a.price?.value ?? 0) - (b.price?.value ?? 0),
      )[0]
    : marketPage.offers[0];

  const onContractClick = () => {
    const searchParams = createSearchParams(location.search);
    searchParams.set('marketPageId', marketPageId);

    if (!hasMultipleOffers) {
      searchParams.set('offerId', marketPage.offers[0].id);
    }

    trackEvent('acquisition_summary_section_contract_clicked', {
      id: marketPageId,
      has_multiple_offers: String(hasMultipleOffers),
    });

    const pathname = hasMultipleOffers
      ? '/acquisition/compare'
      : '/acquisition/contract';

    navigate({ pathname, search: searchParams.toString() });
  };

  const onSalesContactRequest = () => {
    trackEvent('acquisition_summary_section_consultant_clicked', {
      market_page_id: marketPageId,
      page: isCompareUrl ? 'compare' : 'overview',
    });
    openSalesModal(marketPageId);
  };

  const isSingleButton =
    (marketPage.showContractCta || marketPage.showContactSalesCta) &&
    !(marketPage.showContractCta && marketPage.showContactSalesCta);

  return (
    <Container>
      <Content>
        <TextsContainer>
          <Typography.Headline8 color="brand.50">
            {marketPage.headline}
          </Typography.Headline8>
          <Typography.Headline5>{marketPage.title}</Typography.Headline5>
          <Typography.Body3 color="neutral.50">
            {marketPage.description}
          </Typography.Body3>
          {!marketPage.hidePrice && lowestPriceOffer.price && (
            <PriceWithBorder
              value={lowestPriceOffer.price.value}
              unit={lowestPriceOffer.price.unit}
              valueWithDiscount={lowestPriceOffer.price.valueWithDiscount}
            />
          )}
        </TextsContainer>
        <ButtonsContainer $fullWidth={!isSingleButton}>
          {marketPage.showContractCta && (
            <Button size="large" variant="primary" onClick={onContractClick}>
              {hasMultipleOffers
                ? t('summarySection.compareButton')
                : t('summarySection.contractButton')}
            </Button>
          )}
          {marketPage.showContactSalesCta && (
            <Button
              size="large"
              variant={isSingleButton ? 'primary' : 'secondary'}
              onClick={onSalesContactRequest}
              className={cx({ 'disable-grow': !isCompareUrl })}
            >
              {t('talkToConsultant')} <Icons name="IconArrowRight" size={16} />
            </Button>
          )}
        </ButtonsContainer>
      </Content>
      <Image $imageUrl={marketPage.imageUrl} />
    </Container>
  );
};
