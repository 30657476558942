import { Button as BaseButton } from '@flash-tecnologia/hros-web-ui-v2';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const BannersSectionContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => `${theme.colors.neutral[95]}`};
  padding: ${({ theme }) => theme.spacings.l} 0;
`;

export const PrimaryBannerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  padding: ${({ theme }) => theme.spacings.xs};
`;
export const PrimaryBannerInnerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.xs2};
`;

export const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${({ theme }) => theme.spacings.xs3};
  border-radius: ${({ theme }) => theme.borders.radius.m};
  position: relative;
  overflow: hidden;
`;

export const Button = styled(BaseButton)`
  background-color: ${({ theme }) => theme.colors.secondary[50]};
  border-radius: ${({ theme }) => theme.borders.radius.m};
`;
