import AppRouter from './routes';
import { ThemeProvider } from '@flash-tecnologia/hros-web-ui-v2';
import { trpc, trpcClient, queryClient } from './api/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { SalesContactModalProvider } from './context/sales-contact-modal';
import { FlagsProvider } from '@flash-tecnologia/feature-flags';
import { useSession } from './shared/hooks';
import { env } from './lib/env';

import 'swiper/css';

import './shared/i18n';

export default function Root() {
  const { companyId, userId, economicGroupId } = useSession();

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        <FlagsProvider
          appName="hros-web-acquisition"
          url={env.UNLEASH_PROXY_URL}
          token={env.UNLEASH_PROXY_KEY}
          refreshIntervalSeconds={1800}
          initialContext={{
            userId,
            properties: {
              economicGroupId,
              companyId,
              employeeId: userId,
            },
          }}
        >
          <ThemeProvider>
            <SalesContactModalProvider>
              <AppRouter />
            </SalesContactModalProvider>
          </ThemeProvider>
        </FlagsProvider>
      </QueryClientProvider>
    </trpc.Provider>
  );
}
