import styled from 'styled-components';

export const CardGroupContainer = styled.div<{ withBorder?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${({ withBorder, theme }) =>
    withBorder && `border: 1px solid ${theme.colors.secondary[90]};`}
  border-radius: ${({ theme }) => theme.borders.radius.m};
  ${({ withBorder, theme }) => withBorder && `padding: ${theme.spacings.xs};`}
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacings.m};
  gap: ${({ theme }) => theme.spacings.xs};
  max-width: 1029px;
  ${({ withBorder, theme }) =>
    withBorder && `${theme.spacings.m} ${theme.spacings.s};`}
`;

export const CardGroupPlanInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacings.xs5};
`;
export const CardGroupPlansNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
`;

export const CardsContainer = styled.div`
  display: grid;
  gap: ${({ theme }) => theme.spacings.xs1};
  padding: ${({ theme }) => theme.spacings.xs5};
  grid-template-columns: repeat(3, minmax(min-content, max-content));
  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, minmax(min-content, max-content));
  }
  @media (max-width: 900px) {
    grid-template-columns: repeat(1, minmax(min-content, max-content));
  }
`;
